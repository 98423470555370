/* istanbul ignore file */
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';

type CustomCssVarsFnParams = Omit<Parameters<CustomCssVarsFn>[0], 'tpaData'>;

export const customCssVars = (params: CustomCssVarsFnParams) => {
  const imageGalleryPaginationDotsDirection = params.isRTL ? 'row-reverse' : 'row';

  return {
    imageGalleryPaginationDotsDirection,
  };
};
